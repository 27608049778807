<template>
  <div class="sg-web-content-2">
    <el-row
      style="
        background: url('https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/contact-bg-4.png') no-repeat;
        background-size: cover;
      "
    >
      <el-col :sm="{ span: 14, offset: 5 }">
        <div class="sg-map-top-box" >
          <transition name="sg-fade-left-0p2">
            <div
              class="sg-white sg-font-36px sg-gray-feffff sg-text-right"
              style="margin-top: 311px; padding-bottom: 47px"
              v-show="show"
            >
              服务产业园·助推新经济
            </div>
          </transition>
          <div>
            <transition name="sg-fade-in-0p2" appear>
              <div
                style="padding: 128px 13% 39px 13%; z-index: 100; position: relative;"
                class="sg-white-bg sg-flex-center"
              >
                <div>
                  <!-- <div
                    class="sg-red-c31a1f sg-font-30px sg-text-center sg-bold"
                  >
                    正启之星
                  </div> -->
                  <div
                    class="sg-gray-41 sg-font-17px sg-text-center"
                    style="
                      padding-bottom: 17px;
                        30 30;
                    "
                  >
                    财税服务<span style="padding: 0 23px">|</span>医疗服务<span
                      style="padding: 0 23px"
                      >|</span
                    >建筑服务<span style="padding: 0 23px">|</span>人力资源<span
                      style="padding: 0 23px"
                      >|</span
                    >职业培训
                  </div>
                  <div
                    style="
                      width: 552px;
                      height: 4px;
                      background: linear-gradient(90deg,#d3100b,#ea3a29,#f1853f);
                    "
                  ></div>
                  <div
                    class="sg-font-17px sg-gray-41"
                    style="padding-top: 19px"
                  >
                    <span class="sg-bold">企业地址：</span
                    >北京市朝阳区三间房东路1号北京懋隆文化产业创意园17栋A号
                  </div>
                  <div
                    class="sg-font-17px sg-gray-41"
                    style="padding-top: 19px"
                  >
                    <span class="sg-bold">联系电话：</span
                    ><span class="sg-blue-0087fd"
                      >010-65726083&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;400-058-1288</span
                    >
                  </div>
                  <div class="sg-font-17px sg-gray-41 sg-pad-top" style="padding-top: 19px">
                    <span class="sg-bold">企业邮编：</span
                    ><span class="sg-orange-ff9300">100024</span>
                  </div>
                </div>
              </div>
            </transition>
            <transition
              enter-active-class="sg-fade-down-0p8-enter-active"
              leave-active-class="sg-fade-up-0p2-leave-active"
            >
              <div
                v-show="show"
                style="padding: 28px 16% 43px 16%; position: relative;z-index: 0;background: rgb(198, 215, 233)"
                class="sg-green-a0cfb6-bg sg-text-center"
              >
                <div
                  class="sg-green-91bea6 sg-font-hel sg-bold"
                  style="padding-bottom: 15px; font-size: 42px;color: rgb(125, 178, 243)"
                >
                  MAP COORDINATES
                </div>
                <div class="sg-green-48705a sg-font-29px" style="padding-bottom: 16px;color: rgb(0, 135, 253)">地图坐标</div>
                <div>
                  <i
                    class="el-icon-arrow-down sg-green-48705a sg-font-29px" style="color: rgb(0, 135, 253)"
                  ></i>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </el-col>
    </el-row>
    <div id="sg-map" style="height: 769px;"></div>
  </div>
</template>


<script>
export default {
  name: "Contact",
  props: {},
  data: () => {
    return {
      show: false,
    };
  },
  computed: {},
  mounted() {
    this.initMap();
    this.show = true;
  },
  methods: {
    initMap() {
      /* eslint-disable no-undef */
      var center = new TMap.LatLng(39.921565, 116.581336);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      let map = new TMap.Map(document.getElementById("sg-map"), {
        center: center, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
        pitch: 43.5, //设置俯仰角
        rotation: 45, //设置地图旋转角度
      });
      new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 0, y: 0 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(39.921565, 116.581336), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
      new TMap.MultiLabel({
        id: "label-layer",
        map: map, //设置折线图层显示到哪个地图实例中
        //文字标记样式
        styles: {
          label: new TMap.LabelStyle({
            color: "#000000", //颜色属性
            backgroundColor: "#FFFFFF",
            size: 20, //文字大小属性
            offset: { x: 0, y: -30 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: "center", //文字水平对齐属性
            verticalAlignment: "middle", //文字垂直对齐属性
            padding: "8px",
          }),
        },
        //文字标记数据
        geometries: [
          {
            id: "label_1", //点图形数据的标志信息
            styleId: "label", //样式id
            position: center, //标注点位置
            content: "北京市朝阳区三间房东路1号北京懋隆文化产业创意园17栋A号", //标注文本
          },
        ],
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
#sg-map {
  width: 100%;
  height: 600px;
}
.sg-map-top-box {
  margin-bottom: -201px;
  z-index: 1001;
  position: relative;
}
</style>
